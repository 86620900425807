import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// tailwind
import './styles/tailwind.scss'
// styles
import './styles/styles.scss'
// Element Plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueTheMask from 'vue-the-mask'


createApp(App)
.use(router)
.use(ElementPlus)
.use(VueTheMask)
.mount('#app')
